import React from "react";
import { PieChart, Pie, Cell } from "recharts";

const RechartGaugeChart = ({ value, size = 200 }) => {
  let gaugeValue, gradientId, gradientStops, percentage, valueText;

  if (value > 0) {
    // For positive values, fill portion equals the value (0 to 1)
    gaugeValue = value;
    gradientId = "positiveGradient";
    gradientStops = (
      <>
        <stop offset="0%" stopColor="#808080" />
        <stop offset="100%" stopColor="#00cc66" />
      </>
    );
    percentage = Math.round(value * 100);
    valueText = "Positif";
  } else if (value < 0) {
    // For negative values, fill portion equals the absolute value (0 to 1)
    gaugeValue = Math.abs(value);
    gradientId = "negativeGradient";
    gradientStops = (
      <>
        <stop offset="0%" stopColor="#808080" />
        <stop offset="100%" stopColor="#ff4d4d" />
      </>
    );
    percentage = Math.round(Math.abs(value) * 100);
    valueText = "Négatif";
  } else {
    // Neutral: even though percentage is 0, we render a full arc in grey
    gaugeValue = 1;
    gradientId = "neutralGradient";
    gradientStops = (
      <>
        <stop offset="0%" stopColor="#808080" />
        <stop offset="100%" stopColor="#808080" />
      </>
    );
    percentage = 100;
    valueText = "Neutre";
  }

  // Data slices: one for the "filled" part, one for the remainder
  const data = [
    { name: "filled", value: gaugeValue },
    { name: "remainder", value: 1 - gaugeValue },
  ];

  // Chart dimensions and positioning
  const width = size;
  const height = size;
  const cx = width / 2;
  const cy = height / 2;
  const outerRadius = (size / 2) - 10;
  const innerRadius = outerRadius - 30;

  return (
    <PieChart width={width} height={height / 1.8} viewBox={`0 0 ${width} ${height / 1.8}`}>
      <defs>
        <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="0%">
          {gradientStops}
        </linearGradient>
      </defs>

      <Pie
        data={data}
        // Draw a half-circle (from 180° to 0°)
        startAngle={180}
        endAngle={0}
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        dataKey="value"
        stroke="none"
      >
        {/* Filled slice uses the gradient defined above */}
        <Cell fill={`url(#${gradientId})`} />
        {/* Remainder slice (background) stays the same */}
        <Cell fill="hsl(215, 42%, 16%)" />
      </Pie>

      {/* Display the percentage text */}
      <text
        x={cx}
        y={cy - outerRadius / 3.5}
        textAnchor="middle"
        dominantBaseline="middle"
        fontSize="36"
        fill="#fff"
      >
        {valueText}
      </text>

      {/* Left (-1) and right (+1) labels */}
      <text
        x={cx - outerRadius + 50}
        y={cy}
        textAnchor="start"
        dominantBaseline="middle"
        fontSize="10"
        fill="#fff"
      >
        0%
      </text>
      <text
        x={cx + outerRadius - 40}
        y={cy}
        textAnchor="end"
        dominantBaseline="middle"
        fontSize="10"
        fill="#fff"
      >
        100%
      </text>
    </PieChart>
  );
};

export default RechartGaugeChart;
