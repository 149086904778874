//components/Mode/Mode.js

import React, { useState, useEffect } from "react";
import Monogram from "../Logo/Monogram";
import "./Mode.css";

const Mode = ({ onStartCall, isCalling }) => {
    
    if (!isCalling) {
        return (
            <div className="modeContainer" >
                <button className={`modeButton`} onClick={() => onStartCall()}>
                    <i className="fas fa-phone"></i>
                </button>
            </div>
        )
    }
};

export default Mode;