//components/ConfirmModal/ConfirmModal.js

import React from 'react';

import './ConfirmModal.css';

const ConfirmModal = (props) => {
    if (props.type === "delete") {
        return (
            props.isOpen &&
            <div className="modalContainer">
                <div className="overlay" onClick={props.onCancel}></div>
                <div className="confirmModal">
                    <h2>{props.title} <span>{props.subTitle}</span></h2>
                    <p>{props.message}</p>
                    <div className="actions">
                        <button onClick={props.onCancel} className="cta cancel">Annuler</button>
                        <button onClick={props.onConfirm} className="cta confirm">Confirmer</button>
                    </div>
                </div>
            </div>
        )
    } else if (props.type === "forgotPassword") {
        return (
            props.isOpen &&
            <div className="modalContainer">
                <div className="overlay" onClick={props.onCancel}></div>
                <div className={`confirmModal forgotPassword ${props.success ? "success" : ""}`}>
                    {
                        props.success ?
                            <>
                                <i className="fas fa-check"></i>
                                <h2>Un email vous a été envoyé</h2>
                                <p>Veuillez vérifier votre boite mail</p>
                                <button onClick={props.onCancel} className="cta confirm">Fermer</button>
                            </>
                            :
                            <>
                                <h2>{props.title}</h2>
                                <p>{props.message}</p>
                                <div className="form tal">
                                    <input type="email" placeholder="Adresse email" value={props.forgotPasswordEmail} onChange={props.onInputChange} />
                                </div>
                                <div className="actions">
                                    <button onClick={props.onCancel} className="cta cancel">Annuler</button>
                                    <button onClick={props.onConfirm} className="cta confirm">Confirmer</button>
                                </div>
                            </>
                    }
                </div>
            </div>
        )
    } else {
        return "";
    }
};

export default ConfirmModal;