//components/Logo/Wordmark.js

import React from 'react';
import './Wordmark.css';

const Wordmark = (color) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 210.83 39.92"
            className="wordmark"
        >
            <g>
                <path
                    d="M96.1 19.96c0 11.02-8.94 19.96-19.96 19.96s-19.96-8.94-19.96-19.96S65.11 0 76.14 0 96.1 8.94 96.1 19.96m-7.99-.04c0-6.61-5.36-11.97-11.97-11.97s-11.97 5.36-11.97 11.97 5.36 11.97 11.97 11.97 11.97-5.36 11.97-11.97M150.96 38.85v-2.11c-1.09 1.1-2.13 1.69-3.81 2.31-5.73 2.11-12.72-.43-15.77-5.71-7.74-13.37 6.83-29.68 19.57-18.95v-2.95h7.17v27.4h-7.17Zm-.13-13.47c0-3.88-3.14-7.02-7.02-7.02s-7.02 3.14-7.02 7.02 3.14 7.02 7.02 7.02 7.02-3.14 7.02-7.02M191.01 22.41h-7.38c-.44 0-2.39-4.7-7.12-4.19-10.78 1.17-6.18 18.26 3.7 13.41 1.65-.81 3.18-3.32 3.43-3.32h7.38c-2.53 16.02-26.85 14.81-28.26-1.46-1.61-18.51 24.89-22.01 28.26-4.45ZM106.49 11.45 113.23 30l6.2-17.41.36-1.14h7.99l-11.16 27.43-7.77-.07-10.16-27.36zM197.75 2.6h7.6v8.85h5.48v7.17h-5.48v20.23h-7.17V18.62h-4.64v-7.17h4.21zM0 20.78c0-4.8 3.89-8.7 8.7-8.7s8.7 3.89 8.7 8.7-3.89 8.7-8.7 8.7-8.7-3.9-8.7-8.7M22.58 22.07c1.15 10.24 13.54 16.21 21.6 9.37 10.96-9.3 3.76-26.13-9.88-24.2-6.76.96-12.51 7.9-11.72 14.84Z"
                    fill={color.color}
                ></path>
            </g>
        </svg>
    );
};

export default Wordmark;