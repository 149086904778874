import React, { useEffect } from "react";
import AppRoutes from "./routes";
import Background from "./components/Background/Background";
import "./App.css";

function App() {
    return (
        <>
            <Background />
            <AppRoutes />
        </>
    );
}

export default App;
