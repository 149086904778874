import React from "react";
import "./MonthHeatmap.css";

/**
 * @param {Object} props
 * @param {Object} props.dailyMoodScores - day => { score, min, max, ... }
 * @param {number} props.year - The 4-digit year (e.g., 2025)
 * @param {number} props.month - The zero-based month (e.g., 0 for January)
 */
const MonthHeatmap = ({ dailyMoodScores, year = 2025, month = 2 }) => {

  // 1. Generate all dates in the specified month
  const startDate = new Date(year, month, 1);
  const endDate = new Date(year, month + 1, 0); // last day of month
  const daysInMonth = [];
  for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
    daysInMonth.push(new Date(d)); // push a clone
  }

  // Utility to pad single-digit numbers with a leading zero
  const pad = (n) => (n < 10 ? "0" + n : n);

  // 2. Convert a mood score to a color.
  // For negative scores, use red (hue = 0); for positive scores, use green (hue = 120).
  // The further from zero (in either direction), the higher the saturation and lightness.
  const getColor = (score) => {
    if (score == null) {
      // No data => gray
      return "hsl(211, 30%, 22%)";
    }
    // Intensity is the absolute value (in 0..1)
    const intensity = Math.min(Math.abs(score), 1);
    // Use red for negative, green for positive (if score is exactly 0, default to green)
    const hue = score < 0 ? 0 : 120;
    // Increase saturation and lightness with intensity
    const saturation = 30 + 40 * intensity; // from 60% to 100%
    const lightness = 40 + 20 * intensity;   // from 40% to 60%
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

  // 3. Create squares for the actual days
  const daySquares = daysInMonth.map((dayObj) => {
    // Build the key using local date values so it matches dailyMoodScores keys
    const dayKey = `${dayObj.getFullYear()}-${pad(dayObj.getMonth() + 1)}-${pad(dayObj.getDate())}`;
    const moodScore = dailyMoodScores?.[dayKey]?.score;
    const color = getColor(moodScore);
    return (
      <div
        key={dayKey}
        className="dayBox"
        style={{ backgroundColor: color }}
      >
        {dayObj.getDate()}
      </div>
    );
  });

  // 4. If the month has fewer than 40 days, fill the rest with empty squares
  const leftoverCount = 40 - daySquares.length;
  const leftoverSquares = Array.from({ length: leftoverCount }, (_, i) => (
    <div
      key={`dummy-${i}`}
      className="dayBox"
      style={{ backgroundColor: "hsl(211, 30%, 17%)" }}
    />
  ));

  // Combine real day squares + leftover squares
  const allSquares = [...daySquares, ...leftoverSquares];

  return (
    <div className="monthHeatmapContainer">
      {allSquares}
    </div>
  );
};

export default MonthHeatmap;
