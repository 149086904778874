// pages/Test.js
import React, { useState } from "react";
import "../styles/Test.css";
import Loader from "../components/Loader/Loader";

const Test = () => {
  const [loaderType, setLoaderType] = useState("other");
  const [isActive, setIsActive] = useState(false);

  // Called when the Loader completes its end animation.
  const handleComplete = () => {
    console.log("Loader complete for type:", loaderType);
  };

  return (
    <div className="test">
      <div>
        <label htmlFor="loaderType">Choose Loader Type: </label>
        <select
          id="loaderType"
          value={loaderType}
          onChange={(e) => setLoaderType(e.target.value)}
        >
          <option value="other">other</option>
          <option value="ai">ai</option>
          <option value="user">user</option>
        </select>
      </div>

      <div class="ctaContainer">
        <button className="cta" onClick={() => setIsActive(true)}>Start Loader</button>
        <button className="cta" onClick={() => setIsActive(false)}>Stop Loader</button>
      </div>

      <div style={{ marginTop: "2rem" }}>
        <Loader
          type={loaderType}
          isActive={isActive}
          onComplete={handleComplete}
        />
      </div>
    </div>
  );
};

export default Test;
