// src/pages/ResetPassword.js
import React, { useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Wordmark from "../components/Logo/Wordmark";
import "../styles/Login.css";

function ResetPassword() {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Extract token from query parameters, e.g. /reset-password?token=abcdef
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setMessage("Les mots de passe ne correspondent pas.");
      return;
    }
    try {
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/auth/reset-password`, {
        token,
        newPassword,
      });
      setMessage("Réinitialisation réussie! Redirection vers la page de connexion...");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } catch (error) {
      setMessage("Erreur lors de la réinitialisation. Veuillez réessayer.");
    }
  };

  return (
    <main className="loginMain">
      <div className="wrap wrap-S">
        <header>
          <div className="mb32 wrap">
            <Wordmark color="#FFF" />
          </div>
          <h1 className="mb60">Réinitialiser le mot de passe</h1>
        </header>
        <div className="form">
          {message && <p>{message}</p>}
          <input
            placeholder="Nouveau mot de passe"
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            autoComplete="off"
          />
          <input
            placeholder="Confirmer le mot de passe"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            autoComplete="off"
          />
          <div className="mb32 flex alignCenter justifyCenter flexCol gap16 mt40">
            <button className="cta" onClick={handleSubmit}>Réinitialiser</button>
          </div>
        </div>
        <div className=" flex alignCenter justifyCenter flexCol gap8">
          <p><a href="">Conditions d'utilisation</a></p>
          <p><a href="">Politique de confidentialité</a></p>
        </div>
      </div>
    </main>
  );
}

export default ResetPassword;
