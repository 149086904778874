import React, { useEffect } from "react";
import MonthHeatmap from "../MonthHeatmap/MonthHeatmap";
import RechartGaugeChart from "../RechartGaugeChart/RechartGaugeChart";
import "./Dashboard.css";
import Loader from "../Loader/Loader";
import moment from "moment";
import "moment/locale/fr";

const Dashboard = ({ isOpen, setIsOpen, data }) => {
    // Set French locale globally
    moment.locale("fr");

    const getCurrentMonth = () => {
        const date = new Date();
        return date.getMonth();
    };

    const getFrenchMonthName = (year, month) => {
        // Return the French month name for the given year and month
        return moment(new Date(year, month, 1)).locale("fr").format("MMMM");
    };

    // Group conversations by date
    const groupConversations = (conversationsObj) => {
        const groups = {};
        Object.entries(conversationsObj).forEach(([key, conv]) => {
            // Extract the date part from the key (in case key is "YYYY-MM-DD--HHmmss")
            const convDateStr = key.split("--")[0];
            const convDate = moment(convDateStr, "YYYY-MM-DD");
            const today = moment().startOf("day");
            const diffDays = today.diff(convDate, "days");
            let groupKey = "";
            if (diffDays === 0) {
                groupKey = "Aujourd'hui";
            } else if (diffDays === 1) {
                groupKey = "Hier";
            } else if (diffDays < 7) {
                groupKey = "7 jours précédents";
            } else if (diffDays < 30) {
                groupKey = "30 jours précédents";
            } else if (convDate.year() === moment().year()) {
                groupKey = convDate.format("MMMM");
            } else {
                groupKey = convDate.format("YYYY");
            }
            if (!groups[groupKey]) {
                groups[groupKey] = [];
            }
            // Include the key so we can use it later (for a unique key or date extraction)
            groups[groupKey].push({ key, ...conv });
        });
        return groups;
    };

    // Return a FontAwesome icon element based on mood score
    const getMoodEmoji = (score) => {
        if (score == null || score === 0) {
            return <i className="fas fa-meh" style={{ color: "grey" }}></i>;
        }
        if (score > 0) {
            return <i className="fas fa-smile" style={{ color: "var(--color-green)" }}></i>;
        }
        if (score < 0) {
            return <i className="fas fa-frown" style={{ color: "var(--color-red)" }}></i>;
        }
        return <i className="fas fa-meh" style={{ color: "grey" }}></i>;
    };

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";
    }, [isOpen]);

    if (!data) {
        return <Loader />;
    }

    //console.log(data);

    // Today's date key (e.g., "2025-03-09")
    const todayKey = new Date().toISOString().split("T")[0];

    // Determine which key to use for dailyMoodScores and dailyKeywords:
    const dailyMoodKeys = data.dailyMoodScores ? Object.keys(data.dailyMoodScores) : [];
    // If today's key exists, use it; otherwise, fallback to the most recent key (assuming ascending order)
    const usedKey =
        dailyMoodKeys.includes(todayKey) && todayKey !== ""
            ? todayKey
            : dailyMoodKeys.sort()[dailyMoodKeys.length - 1] || todayKey;

    // 1) Today's profile from psychologicalProfile
    const todayProfile = data.psychologicalProfile || "stable";

    // 2) Today's words from dailyKeywords (using the usedKey)
    const todayKeywordsRaw = data.dailyKeywords?.[usedKey] || { positive: {}, negative: {} };
    const positiveWords = todayKeywordsRaw.positive;
    const negativeWords = todayKeywordsRaw.negative;

    const allWords = new Set([...Object.keys(positiveWords), ...Object.keys(negativeWords)]);

    const wordEntries = Array.from(allWords).map((word) => {
        const posCount = positiveWords[word] || 0;
        const negCount = negativeWords[word] || 0;
        const totalCount = posCount + negCount;
        const sentiment = negCount > posCount ? "negative" : "positive";
        return { word, count: totalCount, sentiment };
    });

    const counts = wordEntries.map((entry) => entry.count);
    const minFreq = counts.length ? Math.min(...counts) : 1;
    const maxFreq = counts.length ? Math.max(...counts) : 1;

    const getFontSize = (count) => {
        const minFont = 1.4;
        const maxFont = 4;
        if (minFreq === maxFreq) return `${(minFont + maxFont) / 2}vw`;
        const size = minFont + ((count - minFreq) / (maxFreq - minFreq)) * (maxFont - minFont);
        return `${size}vw`;
    };

    // Group conversations and sort groups by the latest conversation date in each
    const conversationGroups = groupConversations(data.conversations || {});
    const groupsArray = Object.keys(conversationGroups).map((groupKey) => {
        const maxDate = conversationGroups[groupKey].reduce((acc, conv) => {
            const convDateStr = conv.key.split("--")[0];
            const convDate = moment(convDateStr, "YYYY-MM-DD");
            return acc === null || convDate.isAfter(acc) ? convDate : acc;
        }, null);
        return { groupKey, conversations: conversationGroups[groupKey], sortDate: maxDate };
    });
    groupsArray.sort((a, b) => b.sortDate.valueOf() - a.sortDate.valueOf());

    // 4) Today Mood Score from dailyMoodScores (using the usedKey)
    const todayMoodScore = data.dailyMoodScores?.[usedKey]?.score;

    return (
        isOpen && (
            <div className="mainDashboard">
                <button className="closeButton" onClick={() => setIsOpen(false)}>
                    <i className="fas fa-times"></i>
                </button>
                <div className="dashboardContainer">
                    {/* Card 1: Today's Profile */}
                    <div className="card profileCard div1">
                        <label>Humeur du jour</label>
                        <div className="profileValue">{todayProfile}</div>
                        <video src="/looping-abstract.mp4" autoPlay playsInline loop muted></video>
                    </div>

                    {/* Card 2: Today Mood Score */}
                    <div className="card moodScoreCard div2">
                        <label>Dernier score d'humeur ({usedKey})</label>
                        {todayMoodScore !== undefined ? (
                            <RechartGaugeChart value={todayMoodScore} size={350} />
                        ) : (
                            "N/A"
                        )}
                    </div>

                    {/* Card 3: Today's Words (Word Cloud) */}
                    <div className="card wordsCard div3">
                        <label>Mots du jour ({usedKey})</label>
                        <div className="wordCloud">
                            {wordEntries.map((entry, i) => (
                                <span
                                    key={i}
                                    className="wordCloudItem"
                                    style={{
                                        fontSize: getFontSize(entry.count),
                                        transform: `rotate(${Math.random() * 30 - 10}deg)`
                                    }}
                                >
                                    {entry.word}
                                </span>
                            ))}
                        </div>
                    </div>

                    {/* Card 4: Last Conversations (grouped by date) */}
                    <div className="card lastConversationsCard div4">
                        <label>Dernières conversations</label>
                        {groupsArray.map((group) => {
                            const hasMoodScore = group.conversations.some((conv) => conv.moodScore);
                            return hasMoodScore ? (
                                <div key={group.groupKey} className="conversationGroup">
                                    <h4>{group.groupKey}</h4>
                                    <ul>
                                        {group.conversations.map((conv) => (
                                            <li key={conv.key}>
                                                <span style={{ marginRight: "8px" }}>
                                                    {getMoodEmoji(conv.moodScore)}
                                                </span>
                                                {conv.title}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ) : null;
                        })}
                    </div>

                    {/* Card 5: Month Mood Score */}
                    <div className="card calendarCard div5">
                        <label>Humeurs du mois de {getFrenchMonthName(2025, getCurrentMonth())}</label>
                        <MonthHeatmap
                            dailyMoodScores={data.dailyMoodScores}
                            year={2025}
                            month={getCurrentMonth()}
                        />
                    </div>
                </div>
            </div>
        )
    );
};

export default Dashboard;
