import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Connect from "./pages/Connect";
import Chat from "./pages/Chat";
import Test from "./pages/Test";
import ResetPassword from "./pages/ResetPassword";
import Home from "./pages/Home";

const AppRoutes = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/register" element={<Connect />} />
                <Route path="/verify" element={<Connect />} />
                <Route path="/login" element={<Connect />} />
                <Route path="/chat" element={<Chat />} />
                <Route path="/test" element={<Test />} />
                <Route path="/reset-password" element={<ResetPassword />} />
            </Routes>
        </Router>
    );
};

export default AppRoutes;
