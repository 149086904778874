// pages/Chat.js
import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SpeechRecognition, { useSpeechRecognition } from "react-speech-recognition";

import Menu from "../components/Menu/Menu";
import Mode from "../components/Mode/Mode";
import Loader from "../components/Loader/Loader";
import "../styles/Chat.css";

import moment from "moment";

// ─────────────────────────────────────────────
// Typewriter component: displays text progressively.
function Typewriter({ text, speed = 50, onFinish }) {
  const [displayedText, setDisplayedText] = useState("");

  useEffect(() => {
    let current = 0;
    setDisplayedText("");
    const interval = setInterval(() => {
      current++;
      setDisplayedText(text.substring(0, current));
      // Scroll container to bottom every update
      const container = document.querySelector(".messages");
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
      if (current >= text.length) {
        clearInterval(interval);
        if (onFinish) onFinish();
      }
    }, speed);
    return () => clearInterval(interval);
  }, [text, speed, onFinish]);

  return <span>{displayedText}</span>;
}
// ─────────────────────────────────────────────

function Chat() {
  const [message, setMessage] = useState("");
  const [conversations, setConversations] = useState([]);
  const [currentConversation, setCurrentConversation] = useState(null);
  const [userMuted, setUserMuted] = useState(false);
  const [voiceSliderOpen, setVoiceSliderOpen] = useState(false);
  const [selectedVoice, setSelectedVoice] = useState("alloy");
  const availableVoices = [
    { value: "alloy", label: "Voix par défaut (Alloy)" },
    { value: "ash", label: "Ash" },
    { value: "coral", label: "Coral" },
    { value: "echo", label: "Echo" },
    { value: "fable", label: "Fable" },
    { value: "onyx", label: "Onyx" },
    { value: "nova", label: "Nova" },
    { value: "sage", label: "Sage" },
    { value: "shimmer", label: "Shimmer" }
  ];

  // State to track the new assistant message that should be animated.
  const [animatingMessageId, setAnimatingMessageId] = useState(null);

  // Ref to store the currently playing sample audio
  const sampleAudioRef = useRef(null);
  const sampleRequestIdRef = useRef(0);

  // Voice call mode toggles extra UI
  const [callMode, setCallMode] = useState(false);
  // If aiBusy = true, we block user input (no spamming)
  const [aiBusy, setAiBusy] = useState(false);

  const navigate = useNavigate();
  const { transcript, listening, resetTranscript } = useSpeechRecognition();

  // ─────────────────────────────────────────────
  // LOADER STATE MACHINE
  const [loader, setLoader] = useState({
    type: "other", // "ai" | "user" | "other" | null
    active: false,
    key: 0,
  });
  const [pendingLoader, setPendingLoader] = useState(null);

  function nextKey() {
    return loader.key + 1;
  }

  function switchLoader(nextType) {
    if (!nextType) {
      if (loader.active) {
        setPendingLoader(null);
        setLoader((old) => ({ ...old, active: false }));
      } else {
        setLoader((old) => ({ ...old, type: null }));
      }
      return;
    }
    if (loader.active) {
      setPendingLoader(nextType);
      setLoader((old) => ({ ...old, active: false }));
    } else {
      setPendingLoader(null);
      setLoader({
        type: nextType,
        active: true,
        key: nextKey(),
      });
    }
  }

  function handleLoaderComplete() {
    if (pendingLoader) {
      const newType = pendingLoader;
      setPendingLoader(null);
      setLoader({
        type: newType,
        active: true,
        key: nextKey(),
      });
    } else {
      setLoader((old) => ({ ...old, type: null, active: false }));
    }
  }

  // ─────────────────────────────────────────────
  // FETCHING & INIT
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      fetchConversations();
    }
  }, []);

  const fetchConversations = async () => {
    const token = localStorage.getItem("token");
    try {
      const res = await axios.get(`${process.env.REACT_APP_APIURL}/api/chat/conversations`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (res.data.conversations?.length) {
        const sortedConversations = res.data.conversations.sort((a, b) => {
          const dateA = moment(a.date, "YYYY-MM-DD--HHmmss");
          const dateB = moment(b.date, "YYYY-MM-DD--HHmmss");
          //const dateA = new Date(a.date.replace("--", "T")); // Convert "2025-03-05--153956" to "2025-03-05T153956"
          //const dateB = new Date(b.date.replace("--", "T")); // Convert to proper Date object

          return dateB - dateA; // Sort in descending order (most recent first)
        });
        ////console.log("sortedConversations : ", sortedConversations);
        setConversations(sortedConversations);
        setCurrentConversation(sortedConversations[0]);
      } else {
        setConversations([]);
        setCurrentConversation(null);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des conversations:", error);
      setConversations([]);
      setCurrentConversation(null);
    }
  };

  // ─────────────────────────────────────────────
  // TEXT MODE
  const sendMessage = async () => {
    if (!message.trim() || !currentConversation || aiBusy) return;
    setAiBusy(true);

    if (callMode) {
      switchLoader("other");
    }

    const token = localStorage.getItem("token");
    try {
      const body = {
        message: message.trim(),
        conversationKey: currentConversation.date,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/chat/chat`,
        body,
        { headers: { Authorization: `Bearer ${token}` } }
      );

      const updatedList = res.data.conversations || [];
      setConversations(updatedList);

      // Find the conversation that was updated.
      const sameConv = updatedList.find((c) => c.date === currentConversation.date);
      setCurrentConversation(sameConv || updatedList[0]);
      setMessage("");

      // If the last message is from the assistant, mark it for typewriter animation.
      const messages = (sameConv || updatedList[0]).messages;
      const lastMsg = messages[messages.length - 1];
      if (lastMsg && lastMsg.role === "assistant") {
        setAnimatingMessageId(lastMsg.timestamp);
      }

      if (!callMode) {
        setAiBusy(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 429) {
        const errorMessage = err.response.data.message;
        const errorMsgObj = {
          role: "assistant",
          content: errorMessage,
          timestamp: new Date().toISOString(),
        };
        const updatedConv = { ...currentConversation };
        updatedConv.messages = [...updatedConv.messages, errorMsgObj];
        setCurrentConversation(updatedConv);
        setConversations(
          conversations.map((conv) =>
            conv.date === updatedConv.date ? updatedConv : conv
          )
        );
      } else {
        console.error("Error sending message:", err);
      }
      setAiBusy(false);
    }
  };

  const handleKeyDownSendMessage = (e) => {
    if (e.key === "Enter") {
      sendMessage();
    }
  };

  // ─────────────────────────────────────────────
  // CONVERSATION MGMT
  const createNewConversation = async () => {
    if (aiBusy) return;
    const token = localStorage.getItem("token");
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/chat/new-conversation`,
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const newList = res.data.conversations || [];
      setConversations(newList);
      const lastConv = newList[newList.length - 1];
      if (lastConv) setCurrentConversation(lastConv);
    } catch (err) {
      console.error("Error creating new conversation:", err);
    }
  };

  const deleteConv = async (conv) => {
    if (aiBusy) return;
    const token = localStorage.getItem("token");
    try {
      await axios.delete(`${process.env.REACT_APP_APIURL}/api/chat/conversation/${conv.date}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      fetchConversations();
    } catch (err) {
      console.error("Error deleting conversation:", err);
    }
  };

  const selectConversation = (conversation) => {
    if (aiBusy) return;
    setCurrentConversation(conversation);
  };

  // ─────────────────────────────────────────────
  // SCROLL: scroll to bottom when messages update.
  useEffect(() => {
    const container = document.querySelector(".messages");
    if (container) {
      container.scrollTop = container.scrollHeight;
    }
  }, [currentConversation?.messages.length, callMode]);

  // ─────────────────────────────────────────────
  // CALL MODE
  const handleCallMode = () => {
    if (aiBusy) return;
    setCallMode(true);
  };

  const handleHangUp = () => {
    if (aiBusy) return;
    setCallMode(false);
    setUserMuted(false);
    SpeechRecognition.stopListening();
    setAiBusy(false);
    switchLoader(null);
    resetTranscript();
  };

  // ─────────────────────────────────────────────
  // VOICE / STT
  const startListening = () => {
    if (aiBusy) return;
    resetTranscript();
    switchLoader("user");
    SpeechRecognition.startListening({ continuous: true });
  };

  const sendVoiceMessage = async (voiceText) => {
    if (!voiceText.trim() || !currentConversation || aiBusy) return;

    setAiBusy(true);
    switchLoader("other");

    const token = localStorage.getItem("token");
    try {
      const body = { message: voiceText.trim(), conversationKey: currentConversation.date };
      const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/chat/chat`, body, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const updated = res.data.conversations || [];
      setConversations(updated);
      const sameConv = updated.find((c) => c.date === currentConversation.date);
      setCurrentConversation(sameConv || updated[0]);

      resetTranscript();
      speakAIResponse(sameConv || updated[0]);
    } catch (err) {
      console.error("Error sending voice message:", err);
      setAiBusy(false);
      switchLoader(null);
    }
  };

  // ─────────────────────────────────────────────
  // AI TTS
  const speakAIResponse = async (conversation) => {
    const token = localStorage.getItem("token")

    const lastMsg = conversation.messages.filter((m) => m.role === "assistant").pop();
    if (!lastMsg) {
      setAiBusy(false);
      switchLoader(null);
      return;
    }

    SpeechRecognition.stopListening();

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/speech/synthesize`,
        { text: lastMsg.content, voice: selectedVoice },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );

      const audioURL = URL.createObjectURL(res.data);
      const audio = new Audio(audioURL);

      audio.addEventListener("canplaythrough", () => {
        switchLoader("ai");
        audio.play();
      });

      audio.onended = () => {
        setAiBusy(false);
        if (callMode) {
          startListening();
        } else {
          switchLoader(null);
        }
      };
    } catch (error) {
      console.error("Error in TTS:", error);
      setAiBusy(false);
      switchLoader(null);
    }
  };

  // ─────────────────────────────────────────────
  // GREETING

  const greetingArray = [
    "Tu préfères parler ? Très bien, je t'écoute !",
    "Salut, comment vas-tu aujourd'hui ?",
    "Bienvenue, content de te revoir !",
    "Salut, prêt pour de nouveaux échanges ?"
  ];

  useEffect(() => {
    if (callMode && currentConversation) {
      // Check if it's a new conversation: messages not defined or empty.
      const isNewConversation = !currentConversation.messages || currentConversation.messages.length === 1;
      if (isNewConversation) {
        setAiBusy(true);
        switchLoader("ai");
        const token = localStorage.getItem("token");
        axios
          .post(
            `${process.env.REACT_APP_APIURL}/api/speech/synthesize`,
            {
              text: greetingArray[Math.floor(Math.random() * greetingArray.length)],
              voice: currentConversation.voice || "alloy"
            },
            { headers: { Authorization: `Bearer ${token}` }, responseType: "blob" }
          )
          .then((res) => {
            const audioURL = URL.createObjectURL(res.data);
            const audio = new Audio(audioURL);
            SpeechRecognition.stopListening();
            audio.addEventListener("canplaythrough", () => {
              audio.play();
            });
            audio.onended = () => {
              setAiBusy(false);
              startListening();
            };
          })
          .catch((err) => {
            console.error("Error in greeting synthesis:", err);
            setAiBusy(false);
            switchLoader(null);
          });
      } else {
        // If conversation already has messages, directly start listening.
        startListening();
      }
    }
  }, [callMode, currentConversation]);



  // ─────────────────────────────────────────────
  // SILENCE DETECTION
  useEffect(() => {
    if (!callMode) return;
    const silence = setTimeout(() => {
      if (transcript.trim().length > 0 && !aiBusy) {
        SpeechRecognition.stopListening();
        sendVoiceMessage(transcript);
      }
    }, 2000);

    return () => clearTimeout(silence);
  }, [transcript, callMode, aiBusy]);

  // ─────────────────────────────────────────────
  // LISTENING CHANGES
  useEffect(() => {
    if (!callMode) return;
    if (listening && !aiBusy) {
      switchLoader("user");
    }
  }, [listening, callMode, aiBusy]);

  // ─────────────────────────────────────────────
  // UTILITY
  const setFirstLetterUppercase = (str) =>
    !str ? "" :
      str.charAt(0).toUpperCase() + str.slice(1);

  // ─────────────────────────────────────────────
  // USER MUTE
  const handleMuteUser = () => {
    setUserMuted((prevMuted) => {
      const newMuted = !prevMuted;
      if (newMuted) {
        SpeechRecognition.stopListening();
      } else {
        if (callMode) {
          SpeechRecognition.startListening({ continuous: true });
        }
      }
      return newMuted;
    });
  };

  // ─────────────────────────────────────────────
  // VOICE SETTINGS
  const handleVoiceSlider = () => {
    setVoiceSliderOpen(!voiceSliderOpen);
  };

  const handleVoiceChange = async (voice) => {
    setSelectedVoice(voice);
    if (currentConversation) {
      const updatedConv = { ...currentConversation, voice };
      setCurrentConversation(updatedConv);
      setConversations((prev) =>
        prev.map((conv) => (conv.date === updatedConv.date ? updatedConv : conv))
      );

      const token = localStorage.getItem("token");
      try {
        await axios.patch(
          `${process.env.REACT_APP_APIURL}/api/chat/update-voice`,
          { conversationKey: currentConversation.date, voice },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } catch (err) {
        console.error("Erreur lors de la mise à jour de la voix :", err);
      }
    }
  };

  // Updated playVoiceSample: stops any current sample, uses a random sentence, and plays the sample.
  const playVoiceSample = async (voice) => {
    try {
      // Immediately cancel any previous sample audio
      if (sampleAudioRef.current) {
        sampleAudioRef.current.pause();
        sampleAudioRef.current.currentTime = 0;
        sampleAudioRef.current = null;
      }
      // Increase sample request ID
      sampleRequestIdRef.current += 1;
      const currentRequestId = sampleRequestIdRef.current;

      // Array of sample sentences
      const sampleTexts = [
        "Bonjour, ravi de faire ta connaissance. J'ai hâte de discuter avec toi.",
        "Salut, comment vas-tu aujourd'hui ?",
        "Bienvenue, content de te revoir !",
        "Salut, prêt pour une nouvelle conversation ?"
      ];
      // Pick one at random
      const randomSentence = sampleTexts[Math.floor(Math.random() * sampleTexts.length)];
      const token = localStorage.getItem("token");
      // Immediately start synthesis (no delay)
      const res = await axios.post(
        `${process.env.REACT_APP_APIURL}/api/speech/synthesize`,
        { text: randomSentence, voice: voice },
        {
          headers: { Authorization: `Bearer ${token}` },
          responseType: "blob",
        }
      );
      // If a new request has started meanwhile, cancel playing this one.
      if (currentRequestId !== sampleRequestIdRef.current) {
        return;
      }
      const audioURL = URL.createObjectURL(res.data);
      const audio = new Audio(audioURL);
      sampleAudioRef.current = audio;
      audio.play();
    } catch (error) {
      console.error("Erreur lors de la lecture de l'échantillon de voix :", error);
    }
  };

  const handleSliderNext = () => {
    const currentIndex = availableVoices.findIndex((v) => v.value === selectedVoice);
    const nextIndex = (currentIndex + 1) % availableVoices.length;
    const newVoice = availableVoices[nextIndex].value;
    setSelectedVoice(newVoice);
    playVoiceSample(newVoice);
  };

  const handleSliderPrev = () => {
    const currentIndex = availableVoices.findIndex((v) => v.value === selectedVoice);
    const prevIndex = (currentIndex - 1 + availableVoices.length) % availableVoices.length;
    const newVoice = availableVoices[prevIndex].value;
    setSelectedVoice(newVoice);
    playVoiceSample(newVoice);
  };

  // Dot navigation: clicking a dot will change the voice and play its sample.
  const handleDotClick = (voice) => {
    setSelectedVoice(voice.value);
    playVoiceSample(voice.value);
  };

  const handleConfirmVoice = async () => {
    await handleVoiceChange(selectedVoice);
    setVoiceSliderOpen(false);
  };


  // Textarea auto-resize
  const textareaRef = useRef(null);
  const conversationRef = useRef(null);

  const adjustTextareaHeight = (textarea, conversation) => {
    if (!textarea || !conversation) return; // Ensure elements exist

    textarea.style.height = "auto"; // Reset height to recalculate
    const newHeight = Math.min(textarea.scrollHeight, 600); // Limit to max height of 600px
    textarea.style.height = `${newHeight}px`; // Adjust textarea height
    // Scroll textarea to bottom
    textarea.scrollTop = textarea.scrollHeight;

    // Adjust conversation container height
    conversation.style.height = `calc(100% - 24px - ${newHeight}px)`;
  };


  useEffect(() => {
    if (textareaRef.current && conversationRef.current) {
      adjustTextareaHeight(textareaRef.current, conversationRef.current);
    }
  }, [message]); // Update when the message changes



  // ─────────────────────────────────────────────
  // RENDER
  return (
    <>
      {voiceSliderOpen && (
        <div className="voiceSlider fullscreen">
          <button onClick={handleVoiceSlider} className="closeSlider">
            <i className="fas fa-times" />
          </button>
          <div className="sliderContainer">
            <button className="prevButton" onClick={handleSliderPrev}>
              <i className="fas fa-chevron-left" />
            </button>
            <img className="voiceImage" src="/loader/user/loop.gif" alt="Voice" />
            <div className="voiceDisplay">
              {(() => {
                const currentVoice = availableVoices.find((v) => v.value === selectedVoice);
                return <h2>{currentVoice ? currentVoice.label : ""}</h2>;
              })()}
              <ul className="dotnav">
                {availableVoices.map((voice, idx) => (
                  <li
                    key={idx}
                    className={voice.value === selectedVoice ? "active" : ""}
                    onClick={() => handleDotClick(voice)}
                  />
                ))}
              </ul>
            </div>
            <button className="nextButton" onClick={handleSliderNext}>
              <i className="fas fa-chevron-right" />
            </button>
          </div>
          <button onClick={handleConfirmVoice} className="confirmButton cta">
            Valider
          </button>
        </div>
      )}

      <main className="chatContainer">
        <Menu
          conversations={conversations}
          onSelectConversation={selectConversation}
          onNewConversation={createNewConversation}
          onDeleteConversation={deleteConv}
          currentConversation={currentConversation}
        />
        <Mode onStartCall={handleCallMode} isCalling={callMode} />

        {currentConversation && (
          <div className="conversationContainer" ref={conversationRef}>
            {!callMode ? (
              <div className="messages">
                <div className="messageList">
                  {currentConversation.messages.map((msg, idx) => (
                    <div
                      key={idx}
                      className={`${msg.role === "user" ? "user" : "ai"} message`}
                    >
                      <span className="time">
                        {setFirstLetterUppercase(msg.formattedTime)}
                      </span>
                      <div className="messageContent">
                        {msg.role === "assistant" && msg.timestamp === animatingMessageId ? (
                          <Typewriter
                            text={msg.content}
                            speed={10}
                            onFinish={() => setAnimatingMessageId(null)}
                          />
                        ) : (
                          msg.content
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <div className="callScreen">
                {loader.type && (
                  <Loader
                    muted={userMuted}
                    key={loader.key}
                    type={loader.type}
                    isActive={loader.active}
                    onComplete={handleLoaderComplete}
                  />
                )}
                <div className="buttonsContainer">
                  <button onClick={handleMuteUser}>
                    <i className={`fas ${userMuted ? "fa-microphone-slash" : "fa-microphone"}`} />
                  </button>
                  <button onClick={handleHangUp} className="hangupButton">
                    <i className="fas fa-phone-hangup" />
                  </button>
                  <button onClick={handleVoiceSlider}>
                    <i className="fas fa-sliders-simple" />
                  </button>
                </div>
              </div>
            )}
          </div>
        )}

        {!callMode && (
          <div className="keyboardContainer">
            <div className="inputContainer">
              <textarea
                resize="none"
                placeholder="Tapez votre message..."
                value={message}
                maxLength={4096}
                onChange={(e) => {
                  setMessage(e.target.value);
                  adjustTextareaHeight(e.target, conversationRef.current);
                }}
                onInput={(e) => adjustTextareaHeight(e.target, conversationRef.current)}
                onKeyDown={handleKeyDownSendMessage}
                disabled={aiBusy}
                ref={textareaRef}
              />

              <button
                disabled={!message.trim() || aiBusy}
                onClick={sendMessage}
                className="cta circle"
              >
                <i className="fas fa-arrow-up" />
              </button>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

export default Chat;
