// Loader.js
import React, { useState, useEffect } from "react";
import "./Loader.css";

const loaderConfig = {
  ai: {
    sprites: [
      "/loader/ai/start.gif",
      "/loader/ai/loop.gif",
      "/loader/ai/end.gif",
    ],
    durations: {
      start: 900,
      loop: 1500,
      end: 900,
    },
  },
  user: {
    sprites: [
      "/loader/user/start.gif",
      "/loader/user/loop.gif",
      "/loader/user/end.gif",
    ],
    durations: {
      start: 600,
      loop: 1950,
      end: 750,
    },
  },
  other: {
    sprites: [
      "/loader/other/start.gif",
      "/loader/other/loop.gif",
      "/loader/other/end.gif",
    ],
    durations: {
      start: 300,
      loop: 900,
      end: 360,
    },
  },
};

const Loader = ({ type = "other", muted = false, isActive = false, onComplete = () => { } }) => {
  console.log("muted", muted);
  const config = loaderConfig[type];

  // Possible stages: "hidden" | "start" | "loop" | "end"
  const [stage, setStage] = useState("hidden");
  const [opacities, setOpacities] = useState({ start: 0, loop: 0, end: 0 });

  // 1) If isActive becomes true while hidden => "start"
  useEffect(() => {
    if (isActive && stage === "hidden") {
      setStage("start");
    }
  }, [isActive, stage]);

  // 2) "start" => show start.gif for config.durations.start ms, then -> "loop"
  useEffect(() => {
    if (stage === "start") {
      setOpacities({ start: 1, loop: 0, end: 0 });
      const timer = setTimeout(() => {
        setStage("loop");
      }, config.durations.start);
      return () => clearTimeout(timer);
    }
  }, [stage, config.durations.start]);

  // 3) "loop" => show loop.gif indefinitely, 
  //    but if isActive goes false => move to "end"
  useEffect(() => {
    if (stage === "loop") {
      setOpacities({ start: 0, loop: 1, end: 0 });
    }
  }, [stage]);

  useEffect(() => {
    if (stage === "loop" && !isActive) {
      // The moment we detect isActive=false in loop => go end
      setStage("end");
    }
  }, [isActive, stage]);

  // 4) "end" => show end.gif for config.durations.end, then => "hidden", call onComplete
  useEffect(() => {
    if (stage === "end") {
      setOpacities({ start: 0, loop: 0, end: 1 });
      const timer = setTimeout(() => {
        setStage("hidden");
        setOpacities({ start: 0, loop: 0, end: 0 });
        onComplete();
      }, config.durations.end);
      return () => clearTimeout(timer);
    }
  }, [stage, config.durations.end, onComplete]);

  // 5) "hidden" => display none
  return (
    <div
      className={`loader ${muted && type === "user" ? "muted" : ""}`}
      style={{ display: stage === "hidden" ? "none" : "block" }}
    >
      <img src={config.sprites[0]} alt="start" style={{ opacity: opacities.start }} />
      <img src={config.sprites[1]} alt="loop" style={{ opacity: opacities.loop }} />
      <img src={config.sprites[2]} alt="end" style={{ opacity: opacities.end }} />
    </div>
  );
};

export default Loader;
