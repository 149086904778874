import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Wordmark from "../components/Logo/Wordmark";
import ConfirmModal from "../components/ConfirmModal/ConfirmModal";
import Legals from "../components/Legals/Legals";

import "../styles/Login.css";

const Connect = () => {
    // Steps: "register", "verify", "login"
    const navigate = useNavigate();
    const location = useLocation();
    const [pageName, setPageName] = useState("login");

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isVerifying, setIsVerifying] = useState(false);

    // Registration fields
    const [emailRegister, setEmailRegister] = useState("");
    const [passwordRegister, setPasswordRegister] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [inviteCodeRegister, setInviteCodeRegister] = useState("");

    // Track if fields have been blurred
    const [passwordTouched, setPasswordTouched] = useState(false);
    const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

    // For login (for already registered users)
    const [emailLogin, setEmailLogin] = useState("");
    const [passwordLogin, setPasswordLogin] = useState("");

    const [formValid, setFormValid] = useState(false);
    const [error, setError] = useState("");

    // Reveal password states
    const [isPasswordRevealed, setIsPasswordRevealed] = useState(false);
    const [isPasswordConfirmRevealed, setIsPasswordConfirmRevealed] = useState(false);
    const [isLoginPasswordRevealed, setIsLoginPasswordRevealed] = useState(false);

    const [code, setCode] = useState(Array(6).fill(""));

    // Update pageName based on URL so the user can return to the same step
    useEffect(() => {
        if (location.pathname === "/register") {
            setPageName("register");
        } else if (location.pathname === "/verify") {
            setPageName("verify");
        } else {
            setPageName("login");
        }
    }, [location.pathname]);


    useEffect(() => {
        document.body.classList.add("connect-active");

        return () => {
            document.body.classList.remove("connect-active");
        };
    }, []);


    // Password validation: at least 6 characters, 1 digit and 1 uppercase letter.
    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[A-Z])(?=.*\d).{6,}$/;
        return passwordRegex.test(password);
    };

    const isValidEmail = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const isFormValid = (email, password) => {
        return isValidEmail(email) && isValidPassword(password);
    };

    useEffect(() => {
        if (pageName === "register") {
            setFormValid(isFormValid(emailRegister, passwordRegister));
        } else if (pageName === "login") {
            setFormValid(isFormValid(emailLogin, passwordLogin));
        }
    }, [emailRegister, passwordRegister, emailLogin, passwordLogin, pageName]);

    const handleRegister = async (e) => {
        e.preventDefault();
        if (isSubmitting) return;  // Prevent multiple clicks
        setIsSubmitting(true);
        try {
            await axios.post(`${process.env.REACT_APP_APIURL}/api/auth/register`, {
                email: emailRegister,
                password: passwordRegister,
                inviteCode: inviteCodeRegister
            });
            // After successful registration, switch to verification step by updating URL.
            navigate("/verify");
        } catch (error) {
            alert("Registration failed. Try again.");
            console.error(error);
        } finally {
            setIsSubmitting(false);
        }
    };
    const [verifySuccess, setVerifySuccess] = useState(false);
    const handleVerify = async (e) => {
        e.preventDefault();
        if (isVerifying) return;  // Prevent multiple clicks
        setIsVerifying(true);
        const verificationCode = code.join("");
        try {
            const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/auth/verify`, {
                email: emailRegister,
                verificationCode,
            });
            localStorage.setItem("token", res.data.token);
            setError("");
            setVerifySuccess(true);
            setTimeout(() => {
                navigate("/chat");
            }, 3000);

        } catch (error) {
            setError("Verification failed. Please check the code and try again.");
            console.error(error);
        } finally {
            setIsVerifying(false);
        }
    };

    // Handle change for each input
    const handleCodeChange = (index, event) => {
        const newCode = [...code];
        newCode[index] = event.target.value.slice(0, 1); // Only take the first character
        setCode(newCode);

        // Auto-focus the next input if a digit is entered
        if (event.target.value && index < 5) {
            const nextInput = document.getElementById(`code-${index + 1}`);
            if (nextInput) nextInput.focus();
        }
    };

    // Handle keydown to manage backspace navigation and Enter key
    const handleKeyDown = (index, event) => {
        if (event.key === "Backspace" && !code[index] && index > 0) {
            const prevInput = document.getElementById(`code-${index - 1}`);
            if (prevInput) prevInput.focus();
        }
        if (event.key === "Enter") {
            handleVerify();
        }
    };

    // Handle paste to allow pasting the code
    const handlePaste = (event) => {
        event.preventDefault();
        const paste = event.clipboardData.getData("text").slice(0, 6); // Get up to 6 characters
        if (!paste) return;
        const pasteArray = paste.split("");
        const newCode = [...code];
        pasteArray.forEach((char, index) => {
            newCode[index] = char;
        });
        setCode(newCode);

        // Focus the next empty input if available
        if (pasteArray.length < 6) {
            const nextInput = document.getElementById(`code-${pasteArray.length}`);
            if (nextInput) nextInput.focus();
        }
    };

    const handleLogin = async () => {
        try {
            const res = await axios.post(`${process.env.REACT_APP_APIURL}/api/auth/login`, {
                email: emailLogin,
                password: passwordLogin,
            });
            localStorage.setItem("token", res.data.token);
            navigate("/chat");
        } catch (error) {
            alert("Login failed. Check credentials.");
            console.error(error);
        }
    };

    const handlePressEnterRegister = (e) => {
        if (e.key === "Enter") handleRegister(e);
    };

    const handlePressEnterLogin = (e) => {
        if (e.key === "Enter") handleLogin();
    };

    // When changing pages, update URL accordingly.
    const changePage = () => {
        setError("");
        if (pageName === "register" || pageName === "verify") {
            navigate("/login");
        } else {
            navigate("/register");
        }
    };

    // Handle reveal for password fields. This toggles the input type.
    const handleRevealPassword = (e) => {
        const input = e.target.previousSibling;
        input.type = input.type === "password" ? "text" : "password";
        if (input.name === "password") {
            setIsPasswordRevealed(!isPasswordRevealed);
        }
        if (input.name === "confirmpassword") {
            setIsPasswordConfirmRevealed(!isPasswordConfirmRevealed);
        }
        if (input.name === "loginPassword") {
            setIsLoginPasswordRevealed(!isLoginPasswordRevealed);
        }
    };


    // Handle forgot password

    const [showForgotPassword, setShowForgotPassword] = useState(false);
    const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
    const [forgotPasswordError, setForgotPasswordError] = useState("");
    const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);

    const handleShowForgotPassword = () => {
        setShowForgotPassword(true);
    };

    const handleForgotPassword = async () => {
        console.log("forgotPasswordEmail :", forgotPasswordEmail);
        try {
            await axios.post(`${process.env.REACT_APP_APIURL}/api/auth/forgot-password`, {
                email: forgotPasswordEmail,
            });
            setForgotPasswordSuccess(true);
            setForgotPasswordError("");
        } catch (error) {
            setForgotPasswordError("Email not found. Please try again.");
            console.error(error);
        } finally {
            setTimeout(() => {
                setShowForgotPassword(false);
                setForgotPasswordSuccess(false);
                setForgotPasswordEmail("");
            }, 3000);
        }
    };


    const [isOpenLegals, setIsOpenLegals] = useState(false);

    const handleLegals = (e) => {
        setIsOpenLegals(!isOpenLegals);
    };



    // --- RENDERING ---

    // Render for Login step (for already registered users)
    if (pageName === "register") {
        return (
            <>
                <Legals isOpen={isOpenLegals} setIsOpen={setIsOpenLegals} />
                <main className="loginMain">
                    <div className="wrap wrap-S">
                        <header>
                            <div className="mb32 wrap">
                                <a href="/">
                                    <Wordmark color="#FFF" />
                                </a>
                            </div>
                        </header>
                        <div className="form">
                            <h1>Créer mon compte</h1>
                            <p className="subTitle">C'est gratuit et ne prend que quelques secondes.</p>
                            <input
                                name="email"
                                type="email"
                                placeholder="Email"
                                value={emailRegister}
                                onChange={(e) => setEmailRegister(e.target.value)}
                                autoComplete="off"
                            />
                            <div className="passwordWrap">
                                <div className="inputReveal">
                                    <input
                                        autoComplete="new-password"
                                        required
                                        type={isPasswordRevealed ? "text" : "password"}
                                        name="password"
                                        placeholder="Password"
                                        value={passwordRegister}
                                        onChange={(e) => setPasswordRegister(e.target.value)}
                                        onKeyDown={handlePressEnterRegister}
                                        onBlur={() => setPasswordTouched(true)}
                                    />
                                    <i
                                        onClick={handleRevealPassword}
                                        className={`fal ${isPasswordRevealed ? "fa-eye-slash" : "fa-eye"}`}
                                    ></i>
                                </div>
                                <div className="inputReveal">
                                    <input
                                        autoComplete="new-password"
                                        required
                                        type={isPasswordConfirmRevealed ? "text" : "password"}
                                        name="confirmpassword"
                                        placeholder="Confirm password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        onKeyDown={handlePressEnterRegister}
                                        onBlur={() => setConfirmPasswordTouched(true)}
                                    />
                                    <i
                                        onClick={handleRevealPassword}
                                        className={`fal ${isPasswordConfirmRevealed ? "fa-eye-slash" : "fa-eye"}`}
                                    ></i>
                                </div>
                            </div>
                            <input type="text" placeholder="Code Beta Privée" value={inviteCodeRegister} onChange={(e) => setInviteCodeRegister(e.target.value)} />
                            {/* Show error for invalid password only after the password field loses focus */}
                            {passwordTouched && passwordRegister && !isValidPassword(passwordRegister) && (
                                <p className="error-message" style={{ color: "red", marginTop: "8px" }}>
                                    Le mot de passe doit comprendre au moins 6 caractères dont 1 chiffre et une majuscule.
                                </p>
                            )}
                            {/* Show error if passwords do not match, after confirm password is blurred */}
                            {confirmPasswordTouched && passwordRegister && confirmPassword && passwordRegister !== confirmPassword && (
                                <p className="error-message" style={{ color: "red", marginTop: "8px" }}>
                                    Les mots de passe ne correspondent pas.
                                </p>
                            )}
                            <div className="mb32 flex alignCenter justifyCenter flexCol gap16 mt60">
                                <button
                                    className="cta"
                                    disabled={
                                        !emailRegister ||
                                        !passwordRegister ||
                                        !confirmPassword ||
                                        passwordRegister !== confirmPassword ||
                                        !formValid ||
                                        isSubmitting
                                    }
                                    onClick={handleRegister}
                                >
                                    S'inscrire
                                </button>
                                <p className="mt8">
                                    Vous avez déjà un compte ? <a onClick={changePage}>Se connecter</a>
                                </p>
                            </div>
                        </div>
                        <ul className="legals">
                            <li onClick={handleLegals}>Conditions générales d'utilisation</li>
                            <li onClick={handleLegals}>Politique de confidentialité</li>
                        </ul>
                    </div>
                </main>
            </>
        );
    }

    // Render for Verification step
    if (pageName === "verify") {
        return (
            <>
                <Legals isOpen={isOpenLegals} setIsOpen={setIsOpenLegals} />
                <main className={`loginMain ${verifySuccess ? "verifySuccess" : ""}`}>
                    <div className="wrap wrap-S">
                        <header>
                            <div className="mb32 wrap">
                                <a href="/">
                                    <Wordmark color="#FFF" />
                                </a>
                            </div>
                        </header>
                        <div className="form">
                            {verifySuccess ?
                                <h1>Compte vérifié</h1>
                                :
                                <h1>Vérifiez votre compte</h1>
                            }
                            {verifySuccess ?
                                <p className="subTitle">
                                    Votre compte a été vérifié avec succès. Vous allez être redirigé vers la page de chat.
                                </p>
                                :
                                <p className="subTitle">
                                    Un code de vérification a été envoyé à votre adresse email. Veuillez l'entrer ci-dessous.
                                </p>
                            }
                            {verifySuccess ?
                                <div className="codeContainer success">
                                    {code.map((digit, index) => (
                                        <>
                                            <input
                                                key={index}
                                                id={`code-${index}`}
                                                type="text"
                                                className={digit ? "filled" : ""}
                                                maxLength={1}
                                                value={digit}
                                                onChange={(e) => handleCodeChange(index, e)}
                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                onPaste={index === 0 ? handlePaste : null}  // Only the first input handles paste
                                                autoFocus={index === 0}
                                            />

                                            {
                                                index === 2 &&
                                                <span className="separator"></span>
                                            }
                                        </>

                                    ))}
                                </div>
                                :
                                <div className="codeContainer">
                                    {code.map((digit, index) => (
                                        <>
                                            <input
                                                key={index}
                                                id={`code-${index}`}
                                                type="text"
                                                className={digit ? "filled" : ""}
                                                maxLength={1}
                                                value={digit}
                                                onChange={(e) => handleCodeChange(index, e)}
                                                onKeyDown={(e) => handleKeyDown(index, e)}
                                                onPaste={index === 0 ? handlePaste : null}  // Only the first input handles paste
                                                autoFocus={index === 0}
                                            />

                                            {
                                                index === 2 &&
                                                <span className="separator"></span>
                                            }
                                        </>

                                    ))}
                                </div>
                            }
                            {error && (
                                <p className="error-message" style={{ color: "red", marginTop: "8px" }}>
                                    {error}
                                </p>
                            )}
                            {!verifySuccess &&
                                <div className="mb32 flex alignCenter justifyCenter flexCol gap16 mt60">
                                    <button
                                        className="cta"
                                        disabled={
                                            code.join("").length !== 6 ||
                                            isVerifying
                                        }
                                        onClick={handleVerify}
                                    >
                                        Vérifier
                                    </button>
                                </div>
                            }
                        </div>
                        <ul className="legals">
                            <li onClick={handleLegals}>Conditions générales d'utilisation</li>
                            <li onClick={handleLegals}>Politique de confidentialité</li>
                        </ul>
                    </div>
                </main>
            </>
        );
    }



    // Default: Registration step
    return (
        <>
            <Legals isOpen={isOpenLegals} setIsOpen={setIsOpenLegals} />
            <ConfirmModal
                isOpen={showForgotPassword}
                onClose={() => setShowForgotPassword(false)}
                title="Mot de passe oublié"
                message="Entrez votre adresse email pour réinitialiser votre mot de passe."
                type="forgotPassword"
                onInputChange={(e) => setForgotPasswordEmail(e.target.value)}
                email={forgotPasswordEmail}
                error={forgotPasswordError}
                success={forgotPasswordSuccess}
                onConfirm={handleForgotPassword}
                onCancel={() => setShowForgotPassword(false)}
            />

            <main className="loginMain">
                <div className="wrap wrap-S">
                    <header>
                        <div className="mb32 wrap">
                            <a href="/">
                                <Wordmark color="#FFF" />
                            </a>
                        </div>
                    </header>
                    <div className="form">
                        <h1>Se Connecter</h1>
                        <p className="subTitle">Connectez-vous à votre compte.</p>
                        <input
                            name="email"
                            type="email"
                            placeholder="Email"
                            value={emailLogin}
                            onChange={(e) => setEmailLogin(e.target.value)}
                            autoComplete="off"
                        />
                        <div className="inputReveal">
                            <input
                                name="loginPassword"
                                type={isLoginPasswordRevealed ? "text" : "password"}
                                placeholder="Password"
                                value={passwordLogin}
                                onChange={(e) => setPasswordLogin(e.target.value)}
                                onKeyDown={handlePressEnterLogin}
                                autoComplete="off"
                            />
                            <i
                                onClick={handleRevealPassword}
                                className={`fal ${isLoginPasswordRevealed ? "fa-eye-slash" : "fa-eye"}`}
                            ></i>
                        </div>
                        <div className="mb32 flex alignCenter justifyCenter flexCol gap16 mt60">
                            <button className="cta" disabled={!formValid} onClick={handleLogin}>
                                Se connecter
                            </button>
                            <p className="mt8">
                                Vous n'avez pas de compte ? <a onClick={changePage}>S'inscrire</a>
                            </p>
                            <p className="mt24">
                                <a className="forgotPassword" onClick={handleShowForgotPassword}>Mot de passe oublié ?</a>
                            </p>
                        </div>
                    </div>
                    <ul className="legals">
                        <li onClick={handleLegals}>Conditions générales d'utilisation</li>
                        <li onClick={handleLegals}>Politique de confidentialité</li>
                    </ul>
                </div>
            </main>
        </>
    );
};

export default Connect;
