//components/Legals/Legals.js

import React from 'react';
import "./Legals.css";

const Legals = ({ isOpen, setIsOpen }) => {
    return (
        isOpen &&
        <div className="modalContainer legalsModal">
            <div className="overlay" onClick={() => setIsOpen(false)}></div>
            <div className="modal">
                <div className="modalHeader">
                    <h1>Mentions Légales</h1>
                    <button className="close" onClick={() => setIsOpen(false)}><i className="fas fa-times"></i></button>
                </div>
                <div className="modalContent">
                    <div className="contentScroll">
                        <article className="mb16">
                            <p>
                                Bienvenue sur OVACT, votre assistant psychologique numérique IA.
                                OVACT utilise les technologies de OpenAI pour vous fournir des conseils et un soutien psychologique.
                            </p>
                        </article>
                        <article className='mb16'>
                            <h2>Informations Légales</h2>
                            <p>
                                <strong>Nom de l'application :</strong> OVACT<br />
                                <strong>Email :</strong> contact@ovact.com<br />
                            </p>
                        </article>
                        <article className='mb16'>
                            <h2>Hébergement</h2>
                            <p><strong>Hébergeur :</strong> Infomaniak</p>
                            <p><strong>Adresse :</strong> Les Acacias, Genève, Suisse</p>
                            <p><strong>Téléphone :</strong> +41 22 820 35 44</p>
                            <p><strong>Email :</strong> contact@infomaniak.ch</p>
                        </article>
                        <article className='mb16'>
                            <h2>Conditions d'utilisation</h2>
                            <p className="mb8">En utilisant OVACT, vous acceptez les conditions d'utilisation suivantes :</p>
                            <ul>
                                <li>OVACT n'est pas un substitut à une consultation avec un professionnel de la santé mentale.</li>
                                <li>Les conseils fournis par OVACT sont à titre informatif uniquement.</li>
                                <li>OVACT ne peut être tenu responsable des actions entreprises par les utilisateurs suite aux conseils reçus.</li>
                            </ul>
                        </article>
                        <article className='mb16'>
                            <h2>Protection des données</h2>
                            <p className="mb8">
                                OVACT s'engage à protéger la confidentialité de vos données personnelles. Nous collectons et utilisons vos données conformément aux lois en vigueur sur la protection des données. Vos informations ne seront jamais partagées avec des tiers sans votre consentement explicite.
                            </p>
                            <p className="mb8">
                                <strong>Collecte de données :</strong> Nous collectons des informations telles que votre nom, votre adresse email et vos interactions avec l'application pour améliorer nos services.
                            </p>
                            <p className="mb8">
                                <strong>Utilisation des données :</strong> Les données collectées sont utilisées pour personnaliser votre expérience, fournir un support technique et améliorer nos services.
                            </p>
                            <p className="mb8">
                                <strong>Sécurité des données :</strong> Nous mettons en œuvre des mesures de sécurité pour protéger vos informations contre tout accès non autorisé, modification, divulgation ou destruction.
                            </p>
                            <p>
                                <strong>Droits des utilisateurs :</strong> Vous avez le droit d'accéder, de rectifier ou de supprimer vos données personnelles. Pour exercer ces droits, veuillez nous contacter à l'adresse email suivante : contact@ovact.com.
                            </p>
                        </article>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Legals;