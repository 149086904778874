import React, { useEffect, useRef } from "react";
import { Gradient } from "whatamesh";
import "./Background.css";

const Background = () => {
  const canvasRef = useRef(null);
  const gradientRef = useRef(null);

  useEffect(() => {
    if (canvasRef.current) {
      try {
        // Initialize using the provided method with a selector.
        gradientRef.current = new Gradient().initGradient("#bgCanvas");

        // Override the animate function to slow down the animation.
        // Here we multiply the time delta by 0.5 (adjust this factor as needed).
        gradientRef.current.animate = (e) => {
          if (!gradientRef.current.shouldSkipFrame(e) || gradientRef.current.isMouseDown) {
            const delta = Math.min(e - gradientRef.current.last, 1e3 / 15);
            // Multiply by a factor to slow down the time increment.
            gradientRef.current.t += 2 * delta; 
            gradientRef.current.last = e;
            if (gradientRef.current.isMouseDown) {
              let adjustment = 160;
              if (gradientRef.current.isMetaKey) {
                adjustment = -160;
              }
              gradientRef.current.t += adjustment;
            }
            gradientRef.current.mesh.material.uniforms.u_time.value = gradientRef.current.t;
            gradientRef.current.minigl.render();
          }
          if (0 !== gradientRef.current.last && gradientRef.current.isStatic) {
            gradientRef.current.minigl.render();
            return void gradientRef.current.disconnect();
          }
          (gradientRef.current.conf.playing || gradientRef.current.isMouseDown) &&
            requestAnimationFrame(gradientRef.current.animate);
        };

        // Start the initialization (which triggers the animation loop).
        gradientRef.current.init();
      } catch (error) {
        console.error("Whatamesh failed to initialize:", error);
      }
    }

    return () => {
      if (gradientRef.current && typeof gradientRef.current.pause === "function") {
        gradientRef.current.pause();
      }
    };
  }, []);

  return <canvas id="bgCanvas" ref={canvasRef} />;
};

export default Background;
