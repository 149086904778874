import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import "./Menu.css";
import Dashboard from "../Dashboard/Dashboard";
import ConfirmModal from "../ConfirmModal/ConfirmModal";

const Menu = ({ conversations, onSelectConversation, onNewConversation, onDeleteConversation, currentConversation }) => {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [showConversationDetails, setShowConversationDetails] = useState(null);
    const conversationRefs = useRef([]); // Store multiple refs
    const [showProfileDetails, setShowProfileDetails] = useState(false);
    const profileRef = useRef(null);
    const userToken = localStorage.getItem("token");
    const [userProfile, setUserProfile] = useState(null);
    const [profileOpen, setProfileOpen] = useState(false);


    const getUserProfile = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_APIURL}/api/auth/profile`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                //console.log("User Profile:", data);
                setUserProfile(data);
            } else {
                console.error("Get User Profile Error:", response.statusText);
            }
        } catch (error) {
            console.error("Get User Profile Error:", error);
        }
    };

    useEffect(() => {
        if (userToken) {
            getUserProfile();
        }
    }, []);



    const groupConversations = (conversations) => {
        const groups = {};
        conversations.forEach((conv) => {
            // Extract the date part (in case the key is "YYYY-MM-DD--HHmmss")
            const convDateStr = conv.date.split("--")[0];
            const convDate = moment(convDateStr, "YYYY-MM-DD");
            const today = moment().startOf("day");
            const diffDays = today.diff(convDate, "days");

            let groupKey = "";
            if (diffDays === 0) {
                groupKey = "Aujourd'hui";
            } else if (diffDays === 1) {
                groupKey = "Hier";
            } else if (diffDays < 7) {
                groupKey = "7 jours précédents";
            } else if (diffDays < 30) {
                groupKey = "30 jours précédents";
            } else if (convDate.year() === moment().year()) {
                // Groups by month name (e.g., "Janvier", "Février", etc.)
                groupKey = convDate.format("MMMM");
            } else {
                // For older conversations, group by year
                groupKey = convDate.format("YYYY");
            }

            if (!groups[groupKey]) {
                groups[groupKey] = [];
            }
            groups[groupKey].push(conv);
        });
        return groups;
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleLogOut = () => {
        localStorage.removeItem("token");
        navigate("/");
    };

    const handleProfile = () => {
        setShowProfileDetails(!profileOpen);
        setMenuOpen(false);
        setProfileOpen(!profileOpen);
    };
    
    const [showDashboard, setShowDashboard] = useState(false);
    const handleDashboard = () => {
        setMenuOpen(false);
        setShowProfileDetails(false);
        setShowDashboard(!showDashboard);
    };

    const handleChangeConversation = (conversation) => {
        onSelectConversation(conversation);
        toggleMenu();
    };

    const handleNewConversation = () => {
        onNewConversation();
        toggleMenu();
    };

    const handleConversationDetails = (index) => {
        setShowConversationDetails((prevKey) => (prevKey === index ? null : index));
    };

    const handleClickOutsideDetails = (e) => {
        if (
            showConversationDetails !== null &&
            conversationRefs.current[showConversationDetails] &&
            !conversationRefs.current[showConversationDetails].contains(e.target)
        ) {
            setShowConversationDetails(null);
        }
    };

    useEffect(() => {
        if (showConversationDetails !== null) {
            document.addEventListener("mousedown", handleClickOutsideDetails);
        } else {
            document.removeEventListener("mousedown", handleClickOutsideDetails);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideDetails);
        };
    }, [showConversationDetails]);

    const handleShowProfileDetails = () => {
        setShowProfileDetails(!showProfileDetails);
    };

    const handleClickOutsideProfileDetails = (e) => {
        if (showProfileDetails && profileRef.current && !profileRef.current.contains(e.target)) {
            setShowProfileDetails(false);
        }
    };

    const [menuSettingsTab, setMenuSettingsTab] = useState("general");

    const handleMenuSettingsTab = (tab) => {
        setMenuSettingsTab(tab);
    };

    const handleUpdateFirstname = async (e) => {
        e.preventDefault();
        const newFirstname = e.target.firstname.value;
        try {
            const response = await fetch(`${process.env.REACT_APP_APIURL}/api/auth/profile`, {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${userToken}`,
                },
                body: JSON.stringify({ firstName: newFirstname }),
            });
            if (response.ok) {
                const data = await response.json();
                //console.log("Update Firstname:", data);
                getUserProfile();
            } else {
                console.error("Update Firstname Error:", response.statusText);
            }
        } catch (error) {
            console.error("Update Firstname Error:", error);
        }
    };


    const handleResetPassword = async (e) => {
        e.preventDefault();
    };


    //console.log("currentConversation : ", currentConversation);

    useEffect(() => {
        if (showProfileDetails) {
            document.addEventListener("mousedown", handleClickOutsideProfileDetails);
        } else {
            document.removeEventListener("mousedown", handleClickOutsideProfileDetails);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideProfileDetails);
        };
    }, [showProfileDetails]);

    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [conversationToDelete, setConversationToDelete] = useState(null);

    const handleDeleteConversation = (conv) => {
        setMenuOpen(false);
        setShowConversationDetails(null);
        setConversationToDelete(conv);
        setShowConfirmModal(true);
    }

    return (
        <>
            <ConfirmModal
                isOpen={showConfirmModal}
                setIsOpen={setShowConfirmModal}
                type="delete"
                title="Supprimer la conversation"
                subTitle={conversationToDelete ? conversationToDelete.title : ""}
                message="Êtes-vous sûr de vouloir supprimer cette conversation ?"
                onConfirm={() => {
                    onDeleteConversation(conversationToDelete);
                    setShowConfirmModal(false);
                }}
                onCancel={() => setShowConfirmModal(false)}
            />
            <Dashboard
                isOpen={showDashboard}
                setIsOpen={setShowDashboard}
                data={userProfile}
            />
            {
                profileOpen && (
                    <div className="modalContainer">
                        <div className="overlay" onClick={handleProfile}></div>
                        <div className="modal">
                            <div className="modalHeader">
                                <h3>Paramètres</h3>
                                <button onClick={handleProfile}>
                                    <i className="fas fa-times"></i>
                                </button>
                            </div>
                            <div className="modalBody">
                                <nav>
                                    <ul>
                                        <li className={menuSettingsTab === "general" ? "active" : ""} onClick={() => handleMenuSettingsTab("general")}>
                                            <button>
                                                <i className="fal fa-cog"></i> Général
                                            </button>
                                        </li>
                                        <li className={menuSettingsTab === "profile" ? "active" : ""} onClick={() => handleMenuSettingsTab("profile")}>
                                            <button>
                                                <i className="fal fa-user-circle"></i> Profil
                                            </button>
                                        </li>
                                        <li className={menuSettingsTab === "security" ? "active" : ""} onClick={() => handleMenuSettingsTab("security")}>
                                            <button>
                                                <i className="fal fa-shield-keyhole"></i> Sécurité
                                            </button>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="modalContent">
                                    {
                                        menuSettingsTab === "general" && (
                                            <div className="settingsTab">
                                                <label>Thème</label>
                                                <select>
                                                    <option value="dark">Sombre</option>
                                                </select>
                                            </div>
                                        )
                                    }
                                    {
                                        menuSettingsTab === "profile" && (
                                            <div className="settingsTab">
                                                <label htmlFor="firstname">Nom</label>
                                                <input
                                                    type="text"
                                                    id="firstname"
                                                    name="firstname"
                                                    defaultValue={userProfile.firstName ? userProfile.firstName : ""}
                                                    placeholder="Prénom"
                                                />
                                                <button onSubmit={handleUpdateFirstname} className="cta">Enregistrer</button>
                                            </div>
                                        )
                                    }
                                    {
                                        menuSettingsTab === "security" && (
                                            <div className="settingsTab">
                                                <label>Email</label>
                                                <input
                                                    type="email"
                                                    id="email"
                                                    name="email"
                                                    defaultValue={userProfile.email ? userProfile.email : ""}
                                                    placeholder="Email"
                                                    disabled
                                                />

                                                <label>Mot de passe</label>
                                                <input
                                                    type="password"
                                                    id="password"
                                                    name="password"
                                                    placeholder="********"
                                                    disabled
                                                />
                                                <button onSubmit={handleResetPassword} className="cta">Réinitialiser le mot de passe</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
            <button className={`menuIcon ${menuOpen ? "open" : ""}`} onClick={toggleMenu}>
                <div className="icon">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </button>
            {menuOpen &&
                <nav className={`menuNav`}>
                    <div className="overlay" onClick={toggleMenu}></div>
                    <div className="navContainer">
                        <button onClick={handleNewConversation} className="newConversation"><i className="far fa-message-plus"></i></button>
                        <ul className="navListContainer">
                            <div className="conversationsListContainer">
                                {conversations && conversations.length > 0 && (() => {
                                    let globalIndex = 0;
                                    const groupsObj = groupConversations(conversations);
                                    // Create an array of groups with a computed max date for each group.
                                    const groupsArray = Object.keys(groupsObj).map(key => {
                                        const maxDate = groupsObj[key].reduce((acc, conv) => {
                                            const convDateStr = conv.date.split("--")[0];
                                            const convDate = moment(convDateStr, "YYYY-MM-DD");
                                            return acc === null || convDate.isAfter(acc) ? convDate : acc;
                                        }, null);
                                        return { groupKey: key, conversations: groupsObj[key], sortDate: maxDate };
                                    });
                                    // Sort groups so that the most recent (largest sortDate) comes first.
                                    groupsArray.sort((a, b) => b.sortDate.valueOf() - a.sortDate.valueOf());
                                    return groupsArray.map((group) => (
                                        <div key={group.groupKey} className="conversationGroup">
                                            <h4>{group.groupKey}</h4>
                                            <ul>
                                                {group.conversations.map((conv) => {
                                                    const currentIndex = globalIndex;
                                                    globalIndex++;
                                                    return (
                                                        <li
                                                            key={conv.date}
                                                            className={`conversationItem ${currentConversation && currentConversation.date === conv.date ? "active" : ""}`}
                                                        >
                                                            <p onClick={() => handleChangeConversation(conv)}>{conv.title}</p>
                                                            <button onClick={() => handleConversationDetails(currentIndex)} title="Options">
                                                                <i className="fas fa-ellipsis"></i>
                                                            </button>
                                                            {showConversationDetails === currentIndex && (
                                                                <div
                                                                    className="conversationDetails"
                                                                    ref={(el) => (conversationRefs.current[currentIndex] = el)}
                                                                >
                                                                    <ul>
                                                                        <li onClick={() => handleDeleteConversation(conv)} className="delete">
                                                                            <i className="far fa-trash-can"></i> Supprimer
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    ));
                                })()}
                            </div>

                        </ul>
                        <footer>
                            <ul className="profileContainer">
                                <li>
                                    <button className="profile" onClick={handleShowProfileDetails}>
                                        <i className="fas fa-user"></i> <span>{userProfile.firstName ? userProfile.firstName : "Mon profil"}</span>
                                    </button>
                                    {showProfileDetails && (
                                        <div className="profileDetails" ref={profileRef}>
                                            <ul>
                                                <li onClick={handleDashboard}>
                                                    <button>
                                                        <i className="fas fa-dashboard"></i> Tableau de bord
                                                    </button>
                                                </li>
                                                <li onClick={handleProfile}>
                                                    <button>
                                                        <i className="fas fa-cog"></i> Paramètres du compte
                                                    </button>
                                                </li>
                                                <li className="logout" onClick={handleLogOut}>
                                                    <button>
                                                        <i className="fas fa-sign-out-alt"></i> Déconnexion
                                                    </button>
                                                </li>
                                            </ul>
                                        </div>
                                    )}
                                </li>
                            </ul>
                        </footer>
                    </div>
                </nav>
            }
        </>
    );
};

export default Menu;
