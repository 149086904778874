import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Wordmark from "../components/Logo/Wordmark";
import Legals from "../components/Legals/Legals";

import "../styles/Home.css";

const words = ["sans filtre", "personnel", "privé", "sécurisé", "intelligent"];

const TypingEffect = () => {
    const [wordIndex, setWordIndex] = useState(0);
    const [displayedText, setDisplayedText] = useState("");
    const [isDeleting, setIsDeleting] = useState(false);
    const [charIndex, setCharIndex] = useState(0);
    const [isPaused, setIsPaused] = useState(false);

    useEffect(() => {
        const currentWord = words[wordIndex];
        const typingSpeed = 120; // Adjust speed of typing/deleting
        const pauseBeforeDelete = 1000; // 1 second pause before deleting

        if (!isDeleting && charIndex === currentWord.length) {
            setIsPaused(true);
            setTimeout(() => {
                setIsPaused(false);
                setIsDeleting(true);
            }
                , pauseBeforeDelete);
            return;
        }

        if (isDeleting && charIndex === 0) {
            setIsDeleting(false);
            setWordIndex((prev) => (prev + 1) % words.length);
        }

        const timeout = setTimeout(() => {
            setDisplayedText(currentWord.slice(0, charIndex + (isDeleting ? -1 : 1)));
            setCharIndex((prev) => prev + (isDeleting ? -1 : 1));
        }, typingSpeed);

        return () => clearTimeout(timeout);
    }, [charIndex, isDeleting, wordIndex]);

    return <span className={`typing-container ${isPaused ? "paused" : ""}`}>{displayedText}</span>;
};


const Home = () => {
    const navigate = useNavigate();
    const headerRef = useRef(null);
    const mainRef = useRef(null);
    const [isOpenLegals, setIsOpenLegals] = useState(false);

    const handleLegals = (e) => {
        setIsOpenLegals(!isOpenLegals);
    };


    useEffect(() => {
        document.body.classList.add("homepage-active");

        return () => {
            document.body.classList.remove("homepage-active");
        };
    }, []);


    // Scroll listener to add/remove the "scrolled" class on header
    useEffect(() => {
        const scrollContainer = document.body;
        const threshold = 200; // Trigger 100px before the end of <main>
        const handleScroll = () => {
            if (mainRef.current && headerRef.current) {
                const mainBottom = mainRef.current.getBoundingClientRect().bottom;
                //console.log('mainBottom :', mainBottom);
                if (mainBottom <= threshold) {
                    headerRef.current.classList.add("scrolled");
                } else {
                    headerRef.current.classList.remove("scrolled");
                }
            }
        };

        scrollContainer.addEventListener("scroll", handleScroll);
        return () => scrollContainer.removeEventListener("scroll", handleScroll);
    }, []);



    const [activeWhyTab, setActiveWhyTab] = useState(0);

    const whyTabs = [
        {
            title: "💬 Chat & Vocal 24h/24",
            subTitle: "Une oreille toujours disponible, sans jugement",
            content: [
                "Discutez vocalement ou par messages avec une IA entraînée par des psychologues",
                "Analyse en temps réel de vos émotions pour des réponses adaptées",
                "Détection des urgences vitales → redirection immédiate vers le 15, 3114, etc."
            ]
        },
        {
            title: "📊 Votre dashboard bien-être",
            subTitle: "Votre évolution en un clin d'œil",
            content: [
                "Courbe d'humeur interactive et statistiques hebdomadaires",
                "Conseils personnalisés pour améliorer votre bien-être quotidien",
                "Export PDF pour partager votre progression avec un professionnel de santé"
            ]
        },
        {
            title: "🚑 Accès direct aux pro de santé",
            subTitle: "Une passerelle sécurisée vers l'aide humaine*",
            content: [
                "Annuaire vérifié de 850+ psychologues et psychiatres  ",
                "Prise de rendez-vous en quelques clics",
                "Numéros d'urgence intégrés : SAMU, SOS Suicide, Violences conjugales"
            ]
        },
    ];

    const handleWhyTab = (index) => {
        setActiveWhyTab(index);
    };

    const contactRef = useRef(null);
    const [isVisibleContact, setIsVisibleContact] = useState(false);


    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisibleContact(entry.isIntersecting);
            },
            { threshold: 0.2 } // Trigger when 20% of the element is visible
        );

        if (contactRef.current) {
            observer.observe(contactRef.current);
        }

        return () => {
            if (contactRef.current) {
                observer.unobserve(contactRef.current);
            }
        };
    }, []);



    const Lettering = ({ text }) => {
        const words = text.split(' ');
        const fadeInClass = isVisibleContact ? "fadeIn" : '';
        let letterIndex = 0;

        return (
            <>
                {words.map((word, i) => (
                    <span key={i}>
                        {word.split('').map((char, j) => {
                            letterIndex++;
                            const delay = letterIndex * 0.02;
                            return <span className={fadeInClass} key={j} style={{ animationDelay: `${delay}s` }}>{char}</span>;
                        })}
                    </span>
                ))}
            </>
        );
    };



    return (
        <>
            <Legals isOpen={isOpenLegals} setIsOpen={setIsOpenLegals} />
            <header ref={headerRef} className="mainHeader">
                <Wordmark color="#FFF" />
                <nav>
                    <ul>
                        <li onClick={() => navigate("/login")}><i className="fas fa-sign-in mr8"></i>S'identifier</li>
                    </ul>
                </nav>
            </header>
            <main ref={mainRef} className="homeMain">
                <div className="wrap wrap-L">
                    <div className="content">
                        <h1>Votre espace de <br />parole <TypingEffect /></h1>
                        <p>Un confident qui vous écoute, 24/7, parce que parler fait du bien.</p>
                    </div>
                    <div className="imageContainer">
                        <img className="loader" src="/loader/ai/loop.gif" alt="Illustration" />
                        <img className="mockup" src="/mobilechat.png" alt="Illustration" />
                    </div>
                </div>
            </main>
            <section className="homeSection sectionTechnology">
                <div className="wrap wrap-L">
                    <h2>La technologie au service de votre bien-être</h2>
                    <ul>
                        <li>
                            <i className="far fa-microchip"></i>
                            <h3>Technologie empathique</h3>
                            <p>Ovact décrypte vos émotions et apprend à vous connaître pour mieux vous aider</p>
                        </li>
                        <li>
                            <i className="far fa-lock-keyhole"></i>
                            <h3>Confidentialité</h3>
                            <p>Vos données sont cryptées et sécurisées</p>
                        </li>
                        <li>
                            <i className="fas fa-chart-sine"></i>
                            <h3>Suivi évolutif</h3>
                            <p>Des statistiques et analyses pour suivre votre évolution sur le long terme : un véritable journal de bord émotionnel</p>
                        </li>
                        <li>
                            <i className="fas fa-ear-listen"></i>
                            <h3>Disponibilité</h3>
                            <p>Un espace de <br />parole accessible 24/7</p>
                        </li>
                    </ul>
                </div>
            </section>
            <section className="homeSection sectionWhy">
                <div className="wrap wrap-L">
                    <div className="content">
                        <h2>Pourquoi Ovact<br />change la donne ?</h2>
                        <ul>
                            {
                                whyTabs.map((tab, index) => (
                                    <li key={index} className={`${index === activeWhyTab ? "active" : ""} whyElement`} onClick={() => handleWhyTab(index)}>
                                        <h3>{tab.title}<i className={`fas fa-chevron-${index === activeWhyTab ? "up" : "down"}`}></i></h3>
                                        <div className="content">
                                            <h4>{tab.subTitle}</h4>
                                            <ul>
                                                {
                                                    tab.content.map((item, i) => (
                                                        <li key={i}>{item}</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                    </li>
                                ))
                            }
                        </ul>
                    </div>
                    <div className="imgContainer">
                        <img src={`/why${activeWhyTab}.jpg`} alt="Illustration" />
                    </div>
                </div>
            </section>
            <footer className="homeFooter">
                <div className="wrap wrap-L">
                    <a ref={contactRef} href="mailto:contact@ovact.com"><Lettering text="contact@ovact.com" /></a>
                    <ul>
                        <li><p>© {new Date().getFullYear()} Ovact</p></li>
                        <li className="cup" onClick={handleLegals}>Conditions générales d'utilisation</li>
                        <li className="cup" onClick={handleLegals}>Politique de confidentialité</li>
                    </ul>
                </div>
            </footer>
        </>
    );
};

export default Home;
